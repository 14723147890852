<div class="lissa-modal">
  <div class="wrapper">
    <div class="header">
      <h1>{{ "CATEGORYFORM.Create" | translate }}</h1>
      <span class="material-icons close-modal" mat-dialog-close>
        <img src="../../../../assets/icons/cancel_black_new.svg" />
      </span>
    </div>
    <div class="content">
      <div class="subheader-container">
        <h2 class="subheader">
          {{ "CATEGORYFORM.Branch information" | translate }}
        </h2>
        <span class="subheader-subtext">
          {{ "CATEGORYFORM.Please add category tags below" | translate }}
        </span>
      </div>
      <div class="category-input w-100">
        <form
          class="d-flex align-items-center justify-content-center"
          [formGroup]="categoriesForm"
        >
          <mat-form-field appearance="outline" class="full-width">
            <mat-chip-list #chipList class="chip-list-inline">
              <mat-chip
                *ngFor="let category of selectedCategories; let catID = index"
                removable
                (removed)="removeCategory(category?.id, catID)"
              >
                {{ category?.name }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                cdkFocusInitial
                matInput
                [matChipInputFor]="chipList"
                matChipInputAddOnBlur
                formControlName="categories"
                #catName
                (keydown.enter)="addCategory(catName.value)"
                class="inline-input"

              />
            </mat-chip-list>
          </mat-form-field>
          <button
            class="btn btn-primary mr-1 mt-2 ml-2 d-flex justify-content-center"
            [disabled]="!categoriesForm.valid"
            (click)="addCategory(catName.value)"
          >
            {{ "CATEGORYFORM.Add" | translate }}
          </button>
        </form>
      </div>
      <form
        class="form pb-4 m-1 example-form"
        [formGroup]="categoryVariantsForm"
      >
        <mat-dialog-content class="flex-column">
          <div class="subheader-container">
            <h2 class="subheader">
              {{ "CATEGORYFORM.Create Category Variants" | translate }}
            </h2>
          </div>

          <div class="branch-group">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{ "CATEGORYFORM.Select" | translate }}</mat-label>
              <mat-select
                formControlName="client_category_id"
                (selectionChange)="getCategoryVariants($event.value)"
                required
              >
                <mat-option
                  *ngFor="let category of branchCategories"
                  [value]="category?.id"
                >
                  {{ category?.name }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="categoriesForm.get('categories').hasError('required')"
              >
                {{ "Please insert Category" }}
              </mat-error>
            </mat-form-field>
            <div class="flex-wrap" role="group">
              <p *ngIf="!branchInfo.devices">
                {{ "CATEGORYFORM.There is no" | translate }}
              </p>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{"DEVICELIST.Manufacturer code" | translate }}</mat-label>
                <input
                  type="text"
                  matInput
                  autocomplete="off"
                  formControlName="name"
                />
              </mat-form-field>
            </div>
            <div class="d-flex flex-column">
              <mat-checkbox
                *ngFor="let variant of fetchedVariants"
                [checked]="variant?.checked"
                (change)="onVariantSelect($event, variant)"
                >{{ variant?.description || variant?.name }}</mat-checkbox
              >
            </div>
          </div>
        </mat-dialog-content>
      </form>
    </div>
    <div class="buttons">
      <button mat-dialog-close class="btn btn-secondary">
        {{ "MODALS.CANCEL" | translate | uppercase }}
      </button>
      <button
        [disabled]="selectedVariants.length < 1"
        (click)="addVariantsToCategory()"
        class="btn btn-primary"
      >
        {{ "CATEGORYFORM.Save changes" | translate }}
      </button>
    </div>
  </div>
</div>
