<div>
  <div [ngClass]="{'backdrop': showInfo}" (click)="showInfo = false"></div>
  <div class="panel-wrap" [ngClass]="{ 'panel-show': showInfo }">
    <div class="panel">
      <div class="nav col-12 px-0">
        <h1 class="mb-0">{{'BRANCHFORM.Edit Branch' | translate}}</h1>
        <span (click)="showInfo = false"><img src="../../../../assets/icons/x-button.png" /></span>
      </div>

      <div class="divider"></div>
      <br />


      <form class="form ng-pristine example-form" [formGroup]="branchForm" id="ngForm" (ngSubmit)="saveBranch()">
        <div class="row">
          <div class="col-12">
            <div class="card-multis">
              <div class="card-block">
                <div class="row">
                  <div class="col-md-12 col-lg-12">
                    <div class="branch_name">{{'BRANCHFORM.Edit Branch' | translate}}: <span style="color: #0F1825;">
                        {{branchName}} </span> </div>
                    <p style="color: #0f1825; font-size: 16px;">{{ "BRANCHFORM.Branch details" | translate }}</p>
                    <div class="form-branch">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'BRANCHFORM.Choose parent branch' | translate}}</mat-label>
                        <mat-select formControlName="parent_branch_id">
                            <mat-option *ngFor="let branch of parentBranches" [value]="branch?.id">{{branch?.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{ 'BRANCHFORM.Branch Name' | translate }}</mat-label>
                        <input matInput placeholder="{{ 'BRANCHFORM.Branch Name' | translate }} *"
                          formControlName="name" required />
                        <mat-error *ngIf="branchForm.get('name').hasError('required')">
                          {{ "BRANCHFORM.Name is" | translate }}
                          <strong>{{
                            "BRANCHFORM.required" | translate
                            }}</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-block">
                <div class="row">
                  <div class="col-md-12">
                    <p style="color: #0f1825; font-size: 16px;">{{ "BRANCHFORM.Address details" | translate }}</p>
                  </div>
                  <div class="col-md-12 col-lg-12">
                    <div class="branch-group">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'BRANCHFORM.Address line 1' | translate}}</mat-label>
                        <input matInput placeholder="{{
                            'BRANCHFORM.Address line 1' | translate
                          }}" formControlName="address_line_1" required />
                        <mat-error *ngIf="
                            branchForm
                              .get('address_line_1')
                              .hasError('required')
                          ">
                          {{ "Address line 1 is" | translate }}
                          <strong>{{
                            "BRANCHFORM.required" | translate
                            }}</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="branch-group">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'BRANCHFORM.Address line 2' | translate}}</mat-label>
                        <input matInput placeholder="{{
                            'BRANCHFORM.Address line 2' | translate
                          }}" formControlName="address_line_2" />
                      </mat-form-field>
                    </div>
                    <div class="zip-city">
                      <div class="row">
                        <div class="col-6">
                          <div class="branch-group">
                            <mat-form-field class="full-width" appearance="outline">
                              <mat-label>{{'BRANCHFORM.City' | translate}}</mat-label>
                              <input matInput placeholder="{{ 'BRANCHFORM.City' | translate }}" formControlName="city"
                                required />
                              <mat-error *ngIf="branchForm.get('city').hasError('required')">
                                {{ "BRANCHFORM.City is" | translate }}
                                <strong>{{
                                  "BRANCHFORM.required" | translate
                                  }}</strong>
                              </mat-error>
                            </mat-form-field>
                          </div>

                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <mat-form-field class="full-width" appearance="outline">
                              <mat-label>{{'BRANCHFORM.Zip code' | translate}}</mat-label>
                              <input matInput placeholder="{{ 'BRANCHFORM.Zip code' | translate }}"
                                formControlName="zip" />
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <mat-form-field class="full-width" appearance="outline">
                              <mat-label>{{'BRANCHFORM.Country' | translate}}</mat-label>
                              <mat-select formControlName="country_id">
                                <mat-option *ngFor="let country of countries"
                                  [value]="country?.id">{{country?.name}}</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div *ngIf="errorMessage" class="mb-3 errorMessage">
                  {{ errorMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cancel-save">
          <br /><br />
          <a class="btn btn-cancel" (click)="showInfo = false">{{ "BRANCHFORM.Cancel" | translate | titlecase
            }}</a>
          <button class="btn btn-save" [disabled]="!submitable" type="submit">
            {{ "BRANCHFORM.Save changes" | translate | titlecase }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>