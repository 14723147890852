import { Pipe, PipeTransform } from "@angular/core";
import { EmployeeModel, UserModel } from "../models";
import { FullNamePipe } from ".";
@Pipe({ name: "initials" })
export class AvatarInitialsPipe implements PipeTransform {
  transform(user: UserModel | EmployeeModel): string {
    if (user?.id !== undefined) {
      const initials = `${user?.first_name?.substring(0,1)}${user?.last_name?.substring(0, 1)}`;
      return initials.includes("undefined") ? 'NN' : initials;
    }
  }
}
