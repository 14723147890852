<div class="lissa-modal">
  <div class="wrapper">
    <div class="header">
      <h1>
        {{ "ACTIONNEEDEDRESOLVEPOPUP.Action resolve comment" | translate }}
      </h1>
      <span class="material-icons close-modal" mat-dialog-close>
        <img src="../../../../assets/icons/cancel_black_new.svg" />
      </span>
    </div>
    <div class="content">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{
          "ACTIONNEEDEDRESOLVEPOPUP.Comment" | translate
        }}</mat-label>
        <textarea
          class="textarea-input"
          matInput
          rows="5"
          [formControl]="comment"
        ></textarea>
      </mat-form-field>
    </div>
    <div class="buttons">
      <button mat-dialog-close class="btn btn-secondary">
        {{ "MODALS.CANCEL" | translate | uppercase }}
      </button>
      <button (click)="confirm()" class="btn btn-primary">
        {{ "ACTIONNEEDEDRESOLVEPOPUP.CONFIRM" | translate }}
      </button>
    </div>
  </div>
</div>
