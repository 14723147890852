import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeviceModel } from '../../models';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExtendLifecycleModalComponent } from '../../modal-dialogs/extend-lifecycle-modal/extend-lifecycle-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../../services';

@Component({
  selector: 'app-lifecycle-timeline',
  templateUrl: './lifecycle-timeline.component.html',
  styleUrls: ['./lifecycle-timeline.component.scss']
})
export class LifecycleTimelineComponent implements OnInit {
  @Input() device: DeviceModel = new DeviceModel();
  @Output() getDevice: EventEmitter<boolean> = new EventEmitter();

  public dialogConfig = new MatDialogConfig();

  constructor(
    private dialog: MatDialog,
    private deviceService: DeviceService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
  }

  extendLifecycle() {
    this.dialogConfig.width = '450px';
    this.dialogConfig.height = '100vh';    
    this.dialogConfig.position = {right: '0px'};
    this.dialogConfig.panelClass = ['animate__animated','animate__slideInRight', 'animate__faster'];
    this.dialogConfig.data = {text: this.translate.instant('DEVICEINFO.Extend device lifecycle')};
    const extendLifecycleModal = this.dialog.open(ExtendLifecycleModalComponent, this.dialogConfig);

    extendLifecycleModal.afterClosed().subscribe(extendDate => {
      if (extendDate) {
        this.deviceService.updateDevice(
                this.device.company_id,
                this.device?.branch_id,
                this.device?.id,
                extendDate
              ).subscribe(() => {
                this.getDevice.emit(true);
              });
              
      }
    });
  }

}
