<div class="row mt-4" *ngIf="device?.service_cases?.length !== 0 && !isSCCanceled && device?.device_status_id == 4">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <h3 class="section-subheading">
          {{'DEVICEINFO.Service case' | translate }}
                [{{ticketID}}]
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="card" *ngIf="device?.service_cases">
            <div class="card-block">
              <article class="service-steps d-flex justify-content-center">
                <div class="status-progress-wraper col-lg-12">
                  <div *ngFor="let step of icons; let i = index"
                  [ngStyle]="i == 3 && !deviceHasUnresolvedActionNeededStep ? {'display': 'none'} : null"
                  class="text-center"
                  >
                    <mat-icon 
                    class="material-icons-outlined step-icon" 
                    matTooltipClass="progress-tooltip"
                    [class.completed]="isServiceStepCompleted(i)"
                    [class.action]="i == 3 || nextStep(lastServiceStepCompleted) + 1 == i"
                    alt="">{{step?.icon}}</mat-icon>
                    <div 
                    [class.completed]="isServiceStepCompleted(i)"
                    class="text-center"
                    [class.action]="i == 3 || nextStep(lastServiceStepCompleted) + 1 == i"
                    >
                     {{('DEVICEDETAILS.' + step?.text | translate) | uppercase}}
                    </div>
                    <div *ngIf="i == 3 || nextStep(lastServiceStepCompleted) + 1 == i" class="divider active-status"></div>
                  </div>
                </div>
              </article>
              <div class="row divider col-lg-12 mx-auto mt-3"></div>
              <div class="col-lg-12 m-auto py-5">
                <div class="row">
                  <div class="col-6">
                    <p *ngIf="actionNeededText">{{actionNeededText}}</p>
                    <p *ngIf="stepMsg && !actionNeededText">
                      <strong>{{'SERVICESTEPS.' + stepMsg | translate}}</strong>
                    </p>
                    {{'DEVICEDETAILS.Date:' | translate}} {{last_step_completed_date | dateFormat : 'DD.MM.YYYY'}} <br>
                    {{'DEVICEDETAILS.Created by:' | translate}} {{device?.service_cases[device?.service_cases?.length - 1]?.user?.first_name}} {{device?.service_cases[device?.service_cases?.length - 1]?.user?.last_name}}
                  </div>
                  <div *ngIf="actionNeededText" class="col-6 d-flex align-items-end justify-content-end">
                    <div class="">
                      <button class="btn btn-primary case-create ml-auto"
                        (click)="resolveServiceActionNeeded(device)">
                        {{'Resolve' | titlecase}}</button>
                    </div>
                  </div>
                  <div *ngIf="cancelEnabled && !actionNeededText && !currentUser?.read_only" class="col-6 d-flex align-items-end justify-content-end">
                    <div class="">
                      <button 
                      class="btn btn-primary case-create ml-auto"
                      (click)="cancelServiceCase(ticketID)"
                      > 
                        {{'DEVICEDETAILS.Cancel Service Case' | translate | titlecase}}</button>
                    </div>
                  </div>
                  <div class="col-6 d-flex align-items-end justify-content-end" *ngIf="device?.service_cases[device?.service_cases?.length - 1]?.ended_at">
                    <div>
                      <button 
                      class="btn btn-primary case-create ml-auto"
                      (click)="changeStatusEmmiter()"
                      > 
                        {{'complete service case' | titlecase}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>