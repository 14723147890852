import { Injectable } from "@angular/core";
import { HttpRequest, HttpResponse, HttpErrorResponse, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { AuthenticationService } from "../";
import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { tap } from "rxjs/internal/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public company_id;
  public loggedUser;
  public re = /(company\/)(\d+)(\w*)*/;
  private a1Url = `${environment.a1Url}`;
  private assetUrl = `${environment.assetUrl}`;
  // private cache = new Map<string, { data: any, expiration: number }>();

  constructor(
    public auth: AuthenticationService, 
    public router: Router, 
    public route: ActivatedRoute, 
    ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { 
    if(localStorage.getItem('current_user')) {
      this.loggedUser = JSON.parse(localStorage.getItem('current_user'));
      this.company_id = this.loggedUser.user.company_id;
    }
    
    const a1User = this.auth.headers?.user && !this.auth.headers?.ext_sm;

    // Decides which api url should be used based on regular LISSA user or A1 users
    const modifiedRequest = a1User ? request.url.replace(this.assetUrl, this.a1Url) : request.url;
    
    request = request.clone({
      setParams: {'sm_company_id' : this.company_id},
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`,
      },
      url: this.company_id ? modifiedRequest.replace(this.re, (match, p1, p2, p3) => p1 + this.company_id + (p3 ? p3 : '')) : modifiedRequest
    });

    // if (request.method === 'GET' && this.cache.has(request.urlWithParams)) {
    //   const cachedData = this.cache.get(request.urlWithParams);
    //   if (cachedData.expiration > Date.now()) {
    //     return of(new HttpResponse({ body: cachedData.data }));
    //   }
    //   // If the cached data has expired, remove it from the cache
    //   this.cache.delete(request.urlWithParams);
    // }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && request.method === 'GET') {
            // const expirationTime = Date.now() + 300000; // 5 minutes in milliseconds
            // this.cache.set(request.urlWithParams, { data: event.body, expiration: expirationTime });
          }
        },
        (err: any) => this.handleError(err),
      ),
    );
    
  }

  private handleError(err: any): void {
    if (err instanceof HttpErrorResponse) {
      if (err.status === 400 && err.error?.message?.toLowerCase().includes('a1')) {
        this.router.navigate(['/not-found']);
      } else if (err.status === 401) {
        this.handleUnauthorizedError(err);
      }
    }
  }
  
  private handleUnauthorizedError(err: HttpErrorResponse): void {
    if (!this.loggedUser || err.error.message === 'jwt expired') {
      this.router.navigate(['/login']);
    }
  }
}
