// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "http://localhost:8080",
  // assetUrl: "http://localhost:8082/api/ast",
  // authUrl: "http://localhost:8082/auth",
  assetUrl: "https://api2.lissa-ems-test.com/api/ast",
  authUrl: "https://api2.lissa-ems-test.com/auth",
  apiKeyUrl: "https://api2.lissa-ems-test.com/api/keys/api-key",
  docsUrl: "https://api2.lissa-ems-test.com/docs",
  orderUrl: "https://order.lissa-ems-test.com",
  // orderUrl: "http://localhost:4002"
  a1Url: "https://lissa-test.a1.net/api/api/ast",

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
