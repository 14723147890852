<div class="lissa-modal">
  <div class="wrapper">
    <div class="header">
      <h1>{{ "MODALS.Confirm status change" | translate }}</h1>
      <span class="material-icons close-modal" mat-dialog-close>
        <img src="../../../../assets/icons/cancel_black_new.svg" />
      </span>
    </div>
    <div class="content">
      <span>{{ customText }}</span>
    </div>
    <div class="buttons">
      <button mat-dialog-close class="btn btn-secondary">
        {{ "MODALS.CANCEL" | translate | uppercase }}
      </button>
      <button (click)="confirm()" class="btn btn-primary">
        {{ "MODALS.Change" | translate | uppercase }}
      </button>
    </div>
  </div>
</div>
