import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MapsAPILoader } from '@agm/core';
import { RobotModel } from '../..';

@Component({
  selector: 'app-robot-details',
  templateUrl: './robot-details.component.html',
  styleUrls: ['./robot-details.component.scss']
})
export class RobotDetailsComponent implements OnInit {
  latitude: number;
  longitude: number;
  robot = new RobotModel();

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<RobotDetailsComponent>,
    private mapsAPILoader: MapsAPILoader,
  ) { 
    this.latitude = data.robot?.pin?.coordinates[0];
    this.longitude = data.robot?.pin?.coordinates[1];
    this.robot = data?.robot;
  }

  ngOnInit(): void {
    this.mapsAPILoader.load()
  }

}
