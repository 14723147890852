<div class="lissa-modal">
  <div class="wrapper">
    <div class="header">
      <h1>{{customText}}</h1>
      <span class="material-icons close-modal" mat-dialog-close>
        <img src="../../../../assets/icons/cancel_black_new.svg" />
      </span>
    </div>
    <div class="content">
      <form [formGroup]="updateBranchForm">

        <div class="form-group row">
          <div class="col-md-12 col-lg-12">
            <div class="form-group">
              <p class="current-branch">{{'CHANGEBRANCH.Current Branch' | translate}}: <span class="span_text">{{branch?.name}}</span></p>
            </div>
            <div class="form-group">
              <span class="choose-new-branch">{{'CHANGEBRANCH.Choose New Branch' | translate}}</span>
              <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'BRANCHFORM.New Branch' | translate }}</mat-label>
                <mat-select placeholder="{{'CHANGEBRANCH.New Branch' | translate}}" formControlName="branch_id">
                  <mat-option *ngFor="let branch of branchesList" [value]="branch?.id">
                    {{branch?.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <span class="comment">
              {{'CHANGEBRANCH.Additional Notice' | translate}} <p class="optional-title">{{'CHANGEBRANCH.Optional' | translate}}</p>
            </span>
            <app-additional-comment (commentEmmiter)="getComment($event)"></app-additional-comment>
          </div>
        </div>
      </form>
      </div>
      <div class="buttons">
        <button mat-dialog-close class="btn btn-secondary">
          {{'BRANCHFORM.Cancel' | translate | titlecase}}
        </button>
      <button (click)="confirm()" class="btn btn-primary">
        {{'CHANGEBRANCH.CHANGE BRANCH' | translate | titlecase}}
      </button>
    </div>
  </div>
</div>
