import { ServiceStepModel } from ".";

export class ActionNeeded {
  id: number;
  device_id: number;
  message: string;
  resolved: boolean;
  type: string;
  created_at: string;
  updated_at: string;
  additional_info: {};
  service_step_id: number;
  service_case_id: number;
  service_step: ServiceStepModel;
}
