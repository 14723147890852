<div class="device-overview-wrap">
    <h3 class="section-subheading ">
      {{'DEVICEINFO.Device Timeline' | translate}}
    </h3>
    <div class="row">
      <div class="col-12">
       
        <div class="progress">
          <div *ngFor="let log of device?.device_lifecycle_logs" class="progress-bar"
            [ngClass]="'bg-' + log.device_status_id" role="progressbar" [style.width.%]=log.percentage
            data-placement="top" [matTooltip]="log.device_status?.name" matTooltipClass="progress-tooltip"
            title={{log?.device_status?.name}}>
          </div>
        </div>
  
        <div class="date">
          <div class="date-start">
            <span>{{'DEVICEINFO.Start date' | translate }}</span>
            <h4>{{device?.device_lifecycle?.date_in | dateFormat: 'DD.MM.YYYY'}}</h4>
          </div>
          <div class="three-status">
            <div class="activity">
              <div class="color_palete active_status"></div>
              <h1>Active</h1>
            </div>
            <div class="activity">
              <div class="color_palete break_and_fix_status"></div>
              <h1>Break & Fix</h1>
            </div>
            <div class="activity">
              <div class="color_palete pool_status"></div>
              <h1>Pool</h1>
            </div>
          </div>
  
          <div class="date-eol">
            <span>{{'DEVICEINFO.EOL date' | translate }}</span>
            <h4>{{device?.device_lifecycle?.date_end | dateFormat: 'DD.MM.YYYY'}}</h4>
          </div>
        </div>
        <div>
          <button class="btn btn-primary btn-extend" (click)="extendLifecycle()">
            {{'DEVICEINFO.Extend' | translate | titlecase }}
          </button>
        </div>
        
      </div>
    
    </div>
  </div>