import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { BranchModel } from "../models";

@Injectable()
export class BranchService {
  private assetUrl = `${environment.assetUrl}/asset`;
  private adminUrl = `${environment.assetUrl}/admin`;
  
  constructor(private router: Router, private http: HttpClient) {}

  getBranches(company_id: number, active: string, filter?: any, no_join?: boolean, parent_branch?: boolean): Observable<any> {
    let query = `?active=${active}`;
    if (filter != null && filter.branch_id) query = `${query}&name=${filter.branch_id}`
    if (no_join !=null) query = `${query}&no_join=${no_join}`;
    if (parent_branch) query = `${query}&parent_branch=${parent_branch}`
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/branch${query}`)
      .pipe(catchError((error: any) => throwError(error)));
  }
  getBranchesList(company_id: number, active: string): Observable<any> {
    return this.http
      .get(`${this.adminUrl}/company/${company_id}/branch?active=${active}`)
      .pipe(catchError((error: any) => throwError(error)));
  }
  getBranchesPerPage(company_id: number, page:number, limit: number, filter: any): Observable<any> {
    let query = `?page=${page}&limit=${limit}&active=${filter.active}`;
    if (filter.name) query = `${query}&name=${filter.name}`;
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/branch${query}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getBranchById(company_id: number, branch_id: number): Observable<any> {
    return this.http
      .get(`${this.assetUrl}/company/${company_id}/branch/${branch_id}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  createBranch(company_id: number, branch: BranchModel): Observable<any> {
    return this.http
      .post(`${this.assetUrl}/company/${company_id}/branch`, branch)
      .pipe(catchError((error: any) => throwError(error)));
  }

  updateBranch(company_id: number, branch: BranchModel): Observable<any> {
    return this.http
      .put(`${this.assetUrl}/company/${company_id}/branch/${branch.id}`, branch)
      .pipe(catchError((error: any) => throwError(error)));
  }

  deleteBranch(company_id: number, branch_id: number): Observable<any> {
    return this.http
      .delete(`${this.assetUrl}/company/${company_id}/branch/${branch_id}`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  getCountries(name?: string): Observable<any> {
    return this.http.get(`${this.adminUrl}/config/country${name ? '?name=' + name : ''}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getCompanyCountries(company_id: number, branch_id?: number): Observable<any> {
    let query = branch_id ? `?branch_id=${branch_id}` : '';
    
    return this.http.get(`${this.assetUrl}/company/${company_id}/device/get-countries${query}`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  getBranchCategories(company_id: number, branch_id:number): Observable<any> {
    return this.http.get(`${this.assetUrl}/company/${company_id}/branch/${branch_id}/categories`)
    .pipe(catchError((error: any) => throwError(error)));
  }

  exportUserList(company_id: number, filterObject?): Observable<any> {
    let params = new HttpParams();
    
    Object.keys(filterObject).forEach(k => {
        if (filterObject[k] && typeof filterObject[k] != 'object') {
            params = params.set(k, filterObject[k]);
        }
    });

    return this.http.get(`${this.assetUrl}/company/${company_id}/branch/export`, { params })
    .pipe(catchError((error: any) => throwError(error)));
  }

}
