import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DeviceActivitiesModel } from '../../models/device-activities';
import { DeviceService, PrintService, StyleConfiguratorService } from '../../services';
import { DeviceModel, UserModel } from '../../models';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-device-activity',
  templateUrl: './device-activity.component.html',
  styleUrls: ['./device-activity.component.scss']
})
export class DeviceActivityComponent implements OnInit {
  @Input() device: DeviceModel = new DeviceModel();
  @Input() currentUser: UserModel = new UserModel();
  deviceActivities: DeviceActivitiesModel[] = [];
  showMore: boolean = false;
  perPage: number = 5;
  currentPage: number = 1;
  currentLang;
  iconPrefix: string;
  private refreshActivitiesSub: Subscription;

  constructor(
    private deviceService: DeviceService,
    private printService: PrintService,
    public translate: TranslateService,
    private styleConf: StyleConfiguratorService
    ) { 
      setTimeout(() => { this.currentLang = translate.store.currentLang}, 1000);
      this.iconPrefix =  this.styleConf.configuration ? this.styleConf.configuration.name : 'default';
    }

  ngOnInit(): void {
    this.getDeviceActivities();

    this.refreshActivitiesSub = this.deviceService.refreshActivities$
      .subscribe(() => this.getDeviceActivities());
  }

  ngOnDestroy(): void {
      this.refreshActivitiesSub.unsubscribe();
  }

  public getDeviceActivities() {
    this.perPage = this.showMore ? 1000 : 6;
    this.deviceService.getDeviceActivities(this.currentUser.company_id, this.device.id, this.currentPage, this.perPage)
      .subscribe((activities: DeviceActivitiesModel[]) => {
        this.deviceActivities = activities
      });
  }

  public swapTicket(company_id, activity_id) {
    this.deviceService.swapTicket(company_id, activity_id)
    .subscribe((ticket) => {
      const dataToPrint = {
        ticket_id: ticket.ticket_number,
        employee: ticket.user_name,
        original_device_description: ticket.original_device_description,
        swap_device_description: ticket.swap_device_description,
        note: ticket.note
      };
      this.printService
        .printDocument('Swap Ticket', dataToPrint);
    });
  }

  printAssigment(deviceActivity, device) {
    const data = {
      employee: {
        employee_name: {
          first_name: deviceActivity?.employee?.first_name,
          last_name: deviceActivity?.employee?.last_name
        },
        comment: deviceActivity?.comment
      },
      device
    }
    this.printService.printDocument('Assigment', data);
  }

}
