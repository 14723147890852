import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class CompanyService {
  private assetUrl = `${environment.assetUrl}/asset`;

  constructor(private http: HttpClient) { }

  getCompany(): Observable<any> {
    return this.http.get(`${this.assetUrl}/company`)
    .pipe(catchError((error: any) => throwError(error)));
  }
 
}
