<div class="lissa-modal">
  <div class="wrapper">
    <div class="header">
      <h1>{{ "DEVICEINFO.Archive Device" | translate }}</h1>
      <span class="material-icons close-modal" mat-dialog-close>
        <img src="../../../../assets/icons/cancel_black_new.svg" />
      </span>
    </div>
    <div class="content">
      <form [formGroup]="archiveForm">
        <h2 class="title">{{ "MODALS.Why do you" | translate }}</h2>
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{ "MODALS.Select" | translate }}</mat-label>
          <mat-select formControlName="reason">
            <mat-option
              *ngFor="let reason of reasons | async"
              [value]="reason"
              >{{
                "ARCHIVINGPOPUPREASON." + (reason | titlecase) | translate
              }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <div *ngIf="archiveForm.value.reason">
          <span class="archive-device__section-title mb-2"> Upload file </span>
          <div
            class="dropzone-archive-file col-12"
            [style.borderColor]="['#4274F7']"
            ngx-dropzone
            [accept]="'.pdf'"
            (change)="onFileUpload($event)"
          >
            <ngx-dropzone-label>
              <div>
                <img src="../../../../assets/icons/dragANDdrop.svg" />
                <h2 class="drag-drop-text">
                  Drag&Drop or <span style="color: #5757f5">Browse </span> an
                  file
                </h2>
                <h2 class="drag-drop-text">(.pdf)*</h2>
              </div>
            </ngx-dropzone-label>
            <ngx-dropzone-preview
              *ngFor="let f of uploadedFile"
              [file]="f"
              [removable]="true"
              (removed)="onRemove(f, 'cover')"
              class="file-preview"
            >
              <ngx-dropzone-label
                >{{ f.name }} ({{ f.type }})</ngx-dropzone-label
              >
            </ngx-dropzone-preview>
          </div>
        </div>

        <mat-form-field class="full-width" appearance="outline">
          <textarea
            class="textarea-input"
            matInput
            rows="6"
            formControlName="note"
            placeholder="{{ 'MODALS.Write' | translate }}"
          ></textarea>
        </mat-form-field>
      </form>
      <div class="archive-info">
        <h2 class="title">{{ "MODALS.Do you" | translate }}</h2>
        <div class="info-container">
          <span
            >{{ "MODALS.Archive device" | translate }}:
            <span>{{ deviceName }} ({{ deviceSerial }}) </span></span
          >
          <span
            >{{ "MODALS.Employee" | translate }}:
            <span>{{ employee }}</span></span
          >
        </div>
      </div>
      <div class="mt-2 note-container">
        <p class="note">
          <span>*</span> {{ "MODALS.Device will no longer" | translate }}
          {{ "MODALS.The device will also be" | translate }}
        </p>
      </div>
    </div>
    <div class="buttons">
      <button mat-dialog-close class="btn btn-secondary">
        {{ "ARCHIVINGPOPUPWINDOW.No" | translate }}
      </button>
      <button (click)="confirm()" class="btn btn-primary">
        {{ "ARCHIVINGPOPUPWINDOW.Yes" | translate }}
      </button>
    </div>
  </div>
</div>
