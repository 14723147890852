<div class="lissa-modal">
  <div class="wrapper">
    <div class="header">
      <h1>
        {{'MODALS.Assign Device Confirmation' | translate}}
      </h1>
      <span class="material-icons close-modal" mat-dialog-close>
        <img src="../../../../assets/icons/cancel_black_new.svg" />
      </span>
    </div>
    <div class="content">
      <div class="flex-column">
        <h2 class="center">{{'MODALS.Device successfully assigned!' | translate}}</h2>
        <p>{{'MODALS.Assign to Employee' | translate}} {{employeeInfo?.employee_name | fullname}}</p>
        <p>{{'MODALS.Device' | translate}} {{device?.device_variant ? 
          device?.device_variant.description : 
         device?.denormalized_device_variant?.name
         }}</p>
    </div>
    <div class="email-input" *ngIf="emailNotFound">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Mail to"/>
        </mat-form-field>
    </div>
    </div>
    <div class="buttons">
      <button mat-dialog-close class="btn btn-secondary">
        {{ "MODALS.CANCEL" | translate | uppercase }}
      </button>
      <button (click)="confirm()" class="btn btn-primary">
        {{'MODALS.Print confirmation' | translate}}
      </button>
      <button (click)="sendToEmail()" class="btn btn-primary">
        {{'MODALS.Send to email' | translate}}
      </button>
    </div>
  </div>
</div>
