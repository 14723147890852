<div class="robot-modal-wrapper" *ngIf="!fillRequest">
    <div class="flex-column">
      <div class="col-12 col-md-12 pb-3 px-0">
        <div class="change-status-border p-1">
          <div class="d-flex align-items-center justify-content-between">
            <h2 class="left robot-modal-title m-0">{{'MODALS.Select Robot' | translate}}

            </h2>
            <ng-template clas="ng-template-text" #elseTemplate>
                <h2 class="center robot-modal-title m-0">
                    {{customText}}
                </h2>
            </ng-template>
            <img src="/assets/icons/x-button.png" alt="" mat-dialog-close>
          </div>
         
          <div class="divider"></div>
          <p class="left robot-modal-description">
            {{'MODALS.Company branches have different robots' | translate}}
          </p>
          <p class="left robot-modal-subheader">
            {{'MODALS.Choose robot' | translate }}
          </p>
        </div>
      </div>
      <form class="wizard-big wizard clearfix mx-3" [formGroup]="robotForm" role="form">
        <mat-dialog-content class="flex-column p-2">
            <div>
                <i class="pe-7s-trash center"></i>
            </div>
            <div class="col-12 col-md-12">
              <mat-form-field class="full-width p0" appearance="outline">
                <mat-label>Robot</mat-label>
                <mat-select
                formControlName="robot_id"
                aria-labelledby="robot-radio-btn"
               >
                  <mat-option *ngFor="let robot of robotsList" [value]="robot?.id">
                    {{robot?.haai_id}}, <span class="small"> {{robot?.address}}</span>
                  
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        </mat-dialog-content>
   
    </form>
    </div>
  
    <mat-dialog-actions class="action-buttons">
      <button mat-dialog-close class="btn btn-cancel mr-2">
        {{'DEVICECHANGESTATUS.Back' | translate | titlecase}}</button>
      <button type="submit" class="case-create btn btn-primary" (click)="confirm()">{{'MODALS.Select Devices' | translate | titlecase}}</button>
  </mat-dialog-actions>
</div>

<div class="robot-modal-wrapper" *ngIf="fillRequest">

    <form class="mx-3" [formGroup]="robotForm" role="form">
        <mat-dialog-content class="flex-column p-2 content">
            <div class="col-12 col-md-12">
              <div class="change-status-border">
                <div class="d-flex align-items-center justify-content-between">
                  <h2 class="left robot-modal-title m-0">Robot {{action}}</h2>
                  <img src="/assets/icons/x-button.png" alt="" mat-dialog-close>
                </div>
                <div class="divider"></div>
                <p class="left robot-modal-description">
                  Details about robot and devices:
                </p>
              </div>
              <div class="row">
                <div class="col-6 d-flex flex-column justify-content-center">
                  <p class="left mb-0 robot-modal-description">Robot ID: {{robot?.haai_id}}</p>
                  <p class="left mb-0 robot-modal-description">Location: {{robot?.address}}</p>
                  <p class="left mb-0 robot-modal-description">Branches: <span *ngFor="let branch of robot?.storetec_locker_branches">{{branch?.branch?.name}}</span></p>
                  <p class="left mb-0 robot-modal-description">Capacity: {{robot?.capacity}}</p>
                  <p class="left mt-3 robot-modal-description">Devices selected / to {{action}}: {{count}}</p>
                </div>
              </div>
              <div class="divider"></div>
            </div>
            
            <div class="col-12 col-md-12">
              <h2 class="center robot-modal-title m-0">
                QR code expiration days
              </h2>
              <p class="left mb-1 robot-modal-description mt-2">Please select how many days will your QR code last.</p>
              <div class="days-picker d-flex justify-content-between align-items-center">
                <span>7</span>
                  <mat-slider 
                      [min]="7" 
                      [max]="14"
                      [step]="1"
                      discrete
                      (valueChange)="days = $event"
                      class="full-width">
                    <input matSliderThumb>
                  </mat-slider>
                <span>14</span>
                <div class="day-picked ml-5">{{days}}</div>
              </div>
            
              <div class="divider"></div>
            </div>
            <div class="col-12 col-md-12">
              <h2 class="center robot-modal-title m-0">
                QR and PIN code for Robot Fill
              </h2>
              <p class="left mb-1 robot-modal-description mt-2">Please write an e-mail address where we can send QR code for robot fill:</p>
              <mat-form-field class="w-100" appearance="outline">
                <input matInput placeholder="Email" [formControl]="email">
              </mat-form-field>
            </div>
        </mat-dialog-content>
      </form>
      <mat-dialog-actions class="action-buttons">
          <button mat-dialog-close class="btn btn-cancel mr-3">
            {{'DEVICECHANGESTATUS.Back' | translate | titlecase}}</button>
          <button type="submit" class="btn btn-primary" (click)="confirm('email')">{{'MODALS.Send' | translate | titlecase}}</button>
      </mat-dialog-actions>
</div>


